@import "/src/theme.scss";

#division-manpower {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  .table-container {
    padding-bottom: 30px;
  }
  .style-wrapper {
    box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.05);
  }
  .header {
    margin-bottom: 24px;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    .sub-header {
      margin-bottom: 0;
    }
    .controls {
      border: 1px solid $lighterGray;
      padding: 12px 16px;
      border-radius: 12px;
    }
  }

  .spacer {
    margin: 16px auto;
  }
}
