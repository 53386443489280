@import "src/theme.scss";

.job-overview {
  margin-bottom: 36px;

  .error {
    color: $error; // var.$system-error
  }

  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    & > * {
      margin: 0;
    }

    .share-button {
      margin-left: 0;
    }
    .share-button:hover {
      cursor: copy;
    }

    margin-bottom: 16px;
  }

  .summary-wrapper {
    min-height: 400px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.8rem;

    .map {
      object-fit: cover;
      min-width: 350px;
      width: 100%;
      height: 300px;
      border-radius: 0.5em;
      overflow: hidden;
    }

    .map-fallback {
      display: grid;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: 1500px) {
      grid-template-columns: 1fr 350px;

      .map {
        width: 250px;
        height: 100%;
      }
    }

    .summary-info {
      flex: 1;

      .summary-header {
        display: flex;
        justify-content: space-between;

        .link-button {
          font-weight: 700;
          padding-top: 6px;
          padding-bottom: 6px;
          svg {
            margin-left: 6px;
            height: 18px;
          }
        }

        h3 {
          margin: 0;
          margin-bottom: 12px;
          font-size: 21px;
          font-weight: 600;
        }
      }

      .summary-details-grid {
        display: flex;
        gap: 1.5rem;
        align-items: flex-start;
        flex-wrap: wrap;

        .detail-sections {
          width: 100%;

          section {
            border-bottom: 1px solid $border;
            padding: 16px 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            width: 100%;

            @media (min-width: 1200px) {
              grid-template-columns: repeat(3, 1fr);
            }

            p.summary-item-label {
              color: $lightGray;
              margin: 0;
            }
            ul.summary-item-details {
              color: $dark;
              list-style: none;
              padding-left: 0;
              margin: 6px 0;
              li {
                margin: 0 0 6px;
                .null-value {
                  color: $lighterGray;
                }
              }
            }
          }

          section:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    .hero-card {
      min-height: auto;
      min-width: 250px;
    }
  }

  .primary-kpi-cards {
    margin: 24px 0;
    width: 100%;
    display: grid;
    gap: 16px;

    grid-template-columns: repeat(1, 1fr);
    @media (min-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1500px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .kpi-card {
      .financial-text,
      .number-text {
        display: inline;
      }
      .risk-points {
        font-size: 14px;
        color: $gray;
      }
    }

    .kpi-button-card {
      a {
        color: $dark;
        text-decoration: none;
        font-size: 14px;
      }
    }
  }

  .summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;

    .summary-item-label {
      color: $lightGray;
    }
    .summary-item-details {
      color: $dark;
      list-style: none;
      padding-left: 0;
    }
  }

  .job-table {
    min-height: 300px !important;
  }

  .key-select {
    position:absolute;
    top:1rem;
    left:1rem;
  }

  .charts {
    padding-top:16px;
    .chart-card {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .spacer {
    height: 24px;
    width: 100%;
  }
}

@media (min-width: 1500px) {
  .summary-grid {
    .map {
      max-width: 350px;
    }
  }
}
