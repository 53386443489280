@import "/src/theme.scss";

.jobs-sidebar-wrapper {
  border-radius: 12px;
  overflow: hidden;
  min-height: 400px;

  #actions-bar {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    flex-wrap: wrap;
    gap: 10px;

    // & > :nth-child(2n) {
    //   width: 100%;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    // }
  }

  #jobs-sidebar {
    position: relative;
    height: 80vh;
    background-color: white;
    overflow-y: auto;
    box-shadow: 0px -2px 6px -1px rgb(0 0 0 / 2%),
      0px 4px 10px -2px rgb(0 0 0 / 5%); // var.$box-shadow

    // fix for double scrollbar when selected job button is rendered
    &::-webkit-scrollbar {
      width: 0;
    }

    .job-card.wrapper {
      padding: 16px;
      width: 100%;
      text-align: left;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          margin: 10px 0;
          font-size: 18px;
        }

        span.risk-profile {
          background-color: red;
          color: white;
          border-radius: 16px;
          padding: 3px 7px;
        }
      }

      p.customer-number {
        margin-bottom: 0;
        margin-top: 0;
        color: #6f7278;
        font-weight: 500;
      }

      .details {
        display: flex;
        align-items: flex-start;

        gap: 5px;
        font-size: 14px;
        color: #6f7278;

        p {
          margin: 7px 0;
          font-weight: 500;
        }
      }
    }

    .selected-button {
      position: sticky;
      top: 0px;
      left: 0px;
      z-index: 100;
    }

    .job-card.wrapper.selected {
      background-color: #cedcfc; // var.$gray
      border: 2px solid #335eee; // var.$primary

      &:hover {
        cursor: default;
      }
      .details {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p,
        h4 {
          margin-top: 0;
          margin-bottom: 0;
        }

        h4 {
          font-size: 18px;
          color: #262b34;
        }

        .icon-button {
          background-color: transparent;
          box-shadow: none;
          transition: all 0.3s ease;
          &:hover {
            border: 1px solid #335eee;
          }
          &:focus {
            border: 1px solid #335eee;
          }
        }
      }
    }

    .empty-list-message {
      padding: 1rem;
      color: $gray;
      text-align: center;
    }
  }
}

@media (min-width: 1500px) {
  .jobs-sidebar-wrapper {
    position: sticky;
    top: 0;
    height: max-content;
  }
  .jobs-sidebar-wrapper,
  #jobs-sidebar {
    height: max-content;
    min-height: 80vh;
  }
}
