@import "/src/theme.scss";

.division-manpower-forecast-table {
  position: relative;
  box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.05);

  .header {
    margin-bottom: 24px;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    .sub-header {
      margin-bottom: 0;
    }
    .controls {
      border: 1px solid $lighterGray;
      padding: 12px 16px;
      border-radius: 12px;
    }
  }

  .table-wrapper {
    overflow-x: scroll;
    overflow-y: scroll;
    // max-height: 80vh;
    background-color: white;
  }

  table {
    position: relative;
    border-spacing: 0;
    border-radius: 10px;
    table-layout: fixed;
    font-size: $text-s;
    width: 100%;

  }

  td,
  th {
    padding: 12px 16px;
    border: 1px solid $lightestGray;
    overflow: hidden;
    // makes cell width fit stretch to fit its content, affecting the whole column
    white-space: nowrap;
    width: 100px;
  }

  th {
    font-weight: 400;
    border-color: $lightestGray;
    border-bottom-color: $lighterGray;
    border-left: none;
    border-right: none;
    text-align: left;
    height: 160px;
    vertical-align: bottom;

    div {
      position: relative;
      height: 100%;

      span {
        //45 degrees
        position: absolute;
        bottom: 0px;
        left: 5px;
        display: inline-block;
        transform: rotate(-45deg);
        transform-origin: bottom left;
      }
    }
  }

  tr {
    &.filtered {
      color: $lighterGray;
      background-color: transparent;
    }

    td.offWhite {
      background-color: $offWhite;
    }
  }
  .align-right {
    text-align: right !important;
    left: -650px !important;
  }

  .lighter-gray {
    background-color: $lighterGray;
  }
  .lightest-gray {
    background-color: $lightestGray;
  }

  // higher specificity to override thead
  th:nth-child(7),
  th:nth-child(8),
  th:nth-child(9),
  th:nth-child(10),
  th:nth-child(11) {
    background-color: $lightestGray;
  }

  .select-input {
    min-width: 150px;
  }

  .hide-icon {
    border: none;
    padding: 0;
    margin: 0;
    background: none;

    &.filled {
      path {
        stroke: $error;
      }
    }

    &.active {
      path {
        stroke: $primary;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  tbody tr.table-body-header-cell {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    & :first-child {
      border-right: none;
    }
    & :last-child {
      border-left: none;
    }
  }

  /* STICKY TABLE HEADER */
  thead {
    background-color: white;
    z-index: 10;
  }

  /* STICKY COLUMNS */
  thead th:first-child {
    position: sticky;
    background: white;
    left: 0;
    z-index: 2;
  }
  thead th:nth-child(2) {
    position: sticky;
    background: white;
    border-right: 1px solid $lightestGray;
    left: 50px;
    z-index: 2;
  }
  tbody tr td:first-child {
    text-align: left;

    position: sticky;
    background: white;
    left: 0;
    z-index: 10;
  }
  tbody tr:not(.totals-row) td:nth-child(2) {
    position: sticky;
    background: white;
    left: 50px;
    z-index: 2;
    min-width: 180px;
  }
}
