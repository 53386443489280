@import "src/theme.scss";

.filters-wrapper {
  width: 100%;
  margin-bottom: 24px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  & > * {
    flex: 1;
  }

  .applied-filter {
    background: linear-gradient(0deg, rgba(206, 220, 252, 0.25), rgba(206, 220, 252, 0.25)), #FFFFFF;
    border: 1px solid #335EEE;
    border-radius:8px;

    * {
      background: linear-gradient(0deg, rgba(206, 220, 252, 0.25), rgba(206, 220, 252, 0.25)), #FFFFFF;
    }
  }
}

.filter-group {
  border: 1px solid $border; 
  border-radius: 12px;
  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  &:first-child {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 350px;
    }

    gap: 0px;
  }

  .divider {
    display: none;
    width: 1px;
    background-color: $lighterGray;
    height: 20px;

    @media (min-width: 1200px) {
      display: inline;
    }
  }

  p {
    margin: 0;
    padding-right: 8px;
    // border-right: 1px solid rgba(172, 175, 181, 0.3);
  }

  .risk-profile-options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-transform: capitalize;
    justify-content: center;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      strong {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    & > * {
      flex: 1;
    }
  }

  .select-input {
    flex: 1;
    min-width: 150px;
  }

  .custom-risk-button {
    padding: 7px 12px;

    svg {
      margin-right: 6px;
      &.active {
        fill: $primary;
        path {
          stroke: white;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .filters-wrapper {
    flex-direction: row;
  }

  .filters {
    flex-direction: row;
    & > * {
      width: auto;
    }
  }
}

.custom-filters-form {
  position: relative;
  .custom-filters-component-group {
    padding: 24px 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid #acafb5; // var.$border-line

    &:last-child {
      border-bottom: none;
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;

      input {
        width: 75px;
      }
    }

    .toggle-label > div {
      padding-bottom: 0.5rem;
    }
  }
  span.custom-filter-text-input::before {
    content: "> ";
    padding-right: 1rem;
  }
  .spacer {
    margin: 16px 0;
  }
  .tab-panes {
    .tabs {
      display: flex;
      border-bottom: 1px solid $lighterGray;
    }
    .tab-header-button {
      font-weight: 400;
      border: none;
      outline: none;
      border-bottom: 1px solid $lightestGray;
      background-color: white;
      padding: 12px 20px;
      color: $gray;
      &.active {
        border-bottom: 1px solid blue;
        color: $black;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.form-footer {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .buttons-right {
    display: flex;
    gap: 8px;
  }
}

.slider-thumb {
  font-size:10px;
  height:14px !important;
  line-height:14px !important;
  top:0 !important;
  border-radius:5px !important;
}