@import "/src/theme.scss";

.tab-panes {
    height: 100%;
  .tabs {
    display: flex;
    border-bottom: 1px solid $lighterGray;
  }
  .tab-header-button {
    display: flex;
    align-items: center;
    font-weight: 400;
    border: none;
    outline: none;
    border-bottom: 1px solid $lightestGray;
    background-color: white;
    padding: 12px 20px;
    color: $gray;

    &.has-icon {
        svg {
            height: 13px;
            width: 13px;
            margin-left: 6px;
            path {
                stroke: $gray;
            }
        }
    }

    &.active {
      border-bottom: 1px solid blue;
      color: $black;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .tab-pane {
    height: 100%;
  }
}
