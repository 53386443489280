@import "/src/theme.scss";

.hide-job-button {
  background-color: transparent !important;

  &:focus {
    .eye-off,
    .eye-on {
      path {
        stroke: $dark;
      }
    }
  }
}
