.sections {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .section {
    flex: 1 0 calc(50% - 10px);

    &:nth-child(2n + 1):not(:last-child) {
      margin-right: 20px;
    }

    .sub-header {
      margin-bottom: 20px;
    }
  }
}

