@import "../../../theme.scss";

#division-manpower-fallback {
  padding: 24px 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  color: $lightGray;
  text-align: center;

  .header {
    font-size: clamp(24px, 5vw, $heading-l);
    margin-bottom: 12px;
  }

  svg {
    margin-top: 24px;
    max-width: 100%;
  }
}
