.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 24px;

  .kpiCard {
    height: max-content;
  }

  .hero-card {
    min-height: auto;
  }
}

#risk-monitor-page {
  display: grid;
  gap: 1rem;

  #jobs-sidebar,
  .jobs-list {
    overflow-y: auto;
  }

  section.selected-jobs {
    width: calc(100vw - 60px);
  }

  @media (min-width: 700px) {
    section.selected-jobs {
      width: calc(100vw - 168px - 90px);
    }
  }

  @media (min-width: 1000px) {
    grid-template-columns: 350px auto;

    .selected-jobs {
      max-width: calc(100vw - 600px - 2rem);
      padding-right: 30px;
    }
  }

  #risk-overview {
    .hero-cards {
      width: 100%;
    }
    @media (min-width: 1400px) {
      .hero-cards {
        width: 75%;
      }
    }
    @media (min-width: 1800px) {
      .hero-cards {
        width: 50%;
      }
    }

    .hero-cards,
    .cards {
      margin: -6px;
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      & > * {
        margin: 6px;
        flex-grow: 1;
        // flex-basis: 250px;
      }
    }
  }
}
