@import "src/theme.scss";

.job-overview {

  .kpi-card-primary-wrapper {
    span:first-child {
      font-size: 26px;
    }

    span.current-value {
      font-size:18px;
      padding-left: 6px;
    }
  
    span.reference-value {
        font-size: 14px;
        color:  $lightGray;
    }
    
  }
  .kpi-card-secondary {
    color: $gray !important;
    font-size: $text-s !important;
  }

  .row {
    grid-template-columns: repeat(1, 1fr);
    @media (min-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1500px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
