@import "/src/theme.scss";

.save-job-button {
  background-color: transparent !important;

  .star-icon {
    &.saved {
      fill: $yellow;
      path {
        stroke: $yellow;
      }
    }
  }

  &:focus {
    path {
      stroke: $gray;
    }
  }
}
