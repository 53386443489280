.flag {
  text-transform: capitalize;
  .high,
  .low {
    color: white;
  }

  .high {
    background-color: #f04533; // var.$system-error
  }
  .moderate {
    background-color: #ffcf52; // var.$system-warning
  }
  .low {
    background-color: #22C799;
  }

  .large {
    font-size: 1rem;
    padding: 0.2em 0.8em;
    border-radius: 1em;
  }
  .default {
    font-size: 1rem;
    padding: 0.2em 1em;
    border-radius: 1em;
  }
  .small {
    font-size: 0.85rem;
    padding: 0.3em 1em;
    border-radius: 1em;
  }
}
