.app-wrapper {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: stretch;
  max-width: 100%;
}

.navigation {
  padding: 30px;
  padding-right: 0;
  margin-right: 18px;
  flex: 0 0 200px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;


  @media (max-width: 1000px) {
    max-width: 150px;
  }

  .logo {
    display: block;
    margin-bottom: 33px;
  }

  .nav-label {
    margin-bottom: 12px;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  .nav-item {
    margin-bottom: 12px;
  }

  .spacer {
    flex-grow: 1;
  }

  .user {
    background: none !important;
  }

  .job-select {
    width: 100%;
  }
}

main {
  padding: 30px;
  flex-grow: 1;
  overflow: auto;
}

@media (min-width: 701px) {
  .mobile-navigation {
    display: none;
  }
}

@media (max-width: 700px) {
  .navigation {
    position: fixed;
    z-index: 1000;
    background: #fff;
    top: 0;
    bottom: 0;
    left: -100vw;
    padding-right: 30px;
    max-width: none;
    box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
    padding: 20px;
    transition: 0.1s all ease-in-out;

    &.mobile-open {
      left: 0;
    }
  }

  main {
    max-width: 100%;
    padding-bottom: 76px;
  }

  .mobile-navigation {
    height: 76px;
    box-shadow: -5px 0 25px rgb(0 0 0 / 10%);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    z-index: 10;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
  }
}
